import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import { IMAGE_SERVER_URL } from "@/store/shared/ApiRequest";
import Validation from "@/helpers/Validation";
import UsersTable from "@/components/admin/users/UsersTable.vue";
import GroupDialog from "@/components/admin/dialogs/Group/Group.vue";
import GroupParticipants from "@/components/admin/dialogs/GroupParticipants/GroupParticipants.vue";
import ApproveDialog from "@/components/shared/Approve.vue";
import AdditionalAddresses from "@/components/admin/dialogs/Group/AdditionalAddresses.vue";
import GroupModule from "@/store/module/shared/GroupModule";
import AdminModule from "@/store/module/admin/AdminModule";
let GroupPage = class GroupPage extends Vue {
    constructor() {
        super(...arguments);
        this.Validation = Validation;
        this.refreshNonParticipants = false;
        this.update = {
            dialog: false,
        };
        this.addParticipants = {
            dialog: false,
        };
        this.approve = {
            removeParticipants: false,
            removeDocument: false,
            removePhoto: false,
        };
        this.additionalAddresses = {
            dialog: false,
        };
        this.clearSelected = false;
        this.selectedUsers = [];
        this.photos = {
            items: [],
        };
        this.photosUpload = {
            ref: "photos",
            type: "fileupload",
            placeholder: "",
            value: [],
            accept: "image/*",
            onChange: async (files) => {
                if (files === null) {
                    return;
                }
                let arrayOfFiles = [];
                if (Array.isArray(files) === false) {
                    arrayOfFiles = [files];
                }
                else {
                    arrayOfFiles = files;
                }
                if (arrayOfFiles[0] === null || arrayOfFiles.length === 0)
                    return;
                const fd = new FormData();
                for (let file of arrayOfFiles) {
                    fd.append("photos", file);
                }
                const groupId = document.querySelector("[data-group-id]").attributes[0].value;
                fd.append("groupId", groupId);
                const uploadedPhotos = await this.$store.dispatch(GroupModule.types.actions.UPLOAD_PHOTOS, { fd });
                this.photos.items.splice(0);
                for (let photo of uploadedPhotos) {
                    this.photos.items.push(`${IMAGE_SERVER_URL}/public/${photo.src}`);
                }
                this.$notify({
                    type: "success",
                    title: "Фото успешно загруженны",
                });
            },
            rules: [],
            showOnlyForSuperAuth: false,
        };
        this.uploadedDocuments = [];
        this.documentsUpload = {
            ref: "docs",
            type: "fileupload",
            placeholder: "",
            value: [],
            accept: "image/*,application/*",
            onChange: async (files) => {
                if (files === null || files.length === 0) {
                    this.uploadedDocuments.splice(0, this.uploadedDocuments.length);
                    return;
                }
                let arrayOfFiles = [];
                if (Array.isArray(files) === false) {
                    arrayOfFiles = [files];
                }
                else {
                    arrayOfFiles = files;
                }
                const userFiles = arrayOfFiles.map((file) => {
                    file.userName = null;
                    return file;
                });
                for (let file of userFiles) {
                    this.uploadedDocuments.push(file);
                }
            },
            rules: [],
            showOnlyForSuperAuth: false,
        };
        this.documentsTable = {
            selected: [],
            headers: [
                {
                    text: "Название",
                    sortable: true,
                    value: "name",
                },
                {
                    text: "Ссылка",
                    sortable: false,
                    value: "link",
                },
            ],
            items: [],
            search: "",
            loading: true,
            pagesCount: 0,
            itemsPerPage: 5,
            page: 1,
            sortBy: "name",
            showExpand: false,
            singleExpand: false,
        };
        this.group = null;
        this.participantsHeaders = [
            {
                text: "Амплуа",
                sortable: true,
                value: "amplua",
            },
            {
                text: "Фамилия",
                sortable: true,
                value: "surname",
            },
            {
                text: "Имя",
                sortable: true,
                value: "name",
            },
            {
                text: "Отчество",
                sortable: true,
                value: "patronymic",
                align: "center",
            },
            {
                text: "Дата рождения",
                sortable: true,
                value: "birthdate",
            },
            {
                text: "№ билета",
                sortable: true,
                value: "idRfsl",
            },
            {
                text: "Срок действия",
                sortable: true,
                value: "expiryDate",
            },
            {
                text: "Дата вступления",
                sortable: true,
                value: "entryDate",
            },
            { text: "", value: "data-table-expand" },
        ];
    }
    setSelectedUsers(users) {
        this.selectedUsers = users;
    }
    get admin() {
        return this.$store.getters[AdminModule.types.getters.ADMIN];
    }
    get isSuperAdmin() {
        return this.$store.getters[AdminModule.types.getters.IS_SUPER_ADMIN];
    }
    get isAdmin() {
        return !!this.admin;
    }
    get canEdit() {
        if (this.isSuperAdmin)
            return true;
        if (this.isAdmin && this.group.creatorId !== null && this.group.creatorId === this.admin.id)
            return true;
        if (this.isAdmin &&
            this.admin.userId !== null &&
            this.group.executiveId !== null &&
            this.group.executiveId === this.admin.userId)
            return true;
        return false;
    }
    get groupsTable() {
        return this.$store.getters[GroupModule.types.getters.GROUPS_TABLE];
    }
    get avatar() {
        if (!this.group) {
            return `${IMAGE_SERVER_URL}/public/archery-event-placeholder.jpg`;
        }
        return `${IMAGE_SERVER_URL}/public/${this.group.logo}`;
    }
    get participants() {
        return {
            participants: this.group.participants
                .filter((participant) => (participant.participantId === null ? false : true) &&
                !participant.participant.isPremoderated &&
                !participant.participant.isStopped)
                .map((participant) => ({ ...participant.participant, amplua: participant.amplua.name })),
        };
    }
    get profile() {
        if (!this.group) {
            return {
                main: [],
                additional: [],
            };
        }
        return {
            main: [
                { title: "Полное название", value: this.group.fullName },
                { title: "Короткое название", value: this.group.shortName },
                { title: "Код", value: this.group.code },
                { title: "Участников", value: () => this.group.participants.length },
            ],
            additional: [
                {
                    title: "Федеральный округ",
                    value: this.group.contacts.address === null ? "Не указан" : this.group.contacts.address.region.district.name,
                    isTree: false,
                },
                {
                    title: "Регион",
                    value: this.group.contacts.address === null ? "Не указан" : this.group.contacts.address.region.name,
                    isTree: false,
                },
                {
                    title: "Город",
                    value: this.group.contacts.address !== null && this.group.contacts.address.city !== null
                        ? this.group.contacts.address.city.name
                        : "Не указан",
                    isTree: false,
                },
                {
                    title: "Адрес",
                    value: this.group?.contacts?.addressText ? this.group.contacts.addressText : "Не указан",
                    isTree: false,
                },
                { title: "Email", value: this.group.contacts.email, isTree: false },
                { title: "Телефон", value: this.group.contacts.phone, isTree: false },
                {
                    title: "Сайт",
                    value: this.group.contacts?.website ? this.group.contacts?.website : "Не указан",
                    isTree: false,
                },
            ],
        };
    }
    async removeParticipants() {
        const ids = this.selectedUsers.map((user) => this.group.participants.find((item) => item.participantId === user.id).id);
        await this.$store.dispatch(GroupModule.types.actions.DELETE_PARTICIPANTS, {
            groupId: this.group.id,
            participantsIds: ids,
        });
        await this.onGroupChanged();
        this.refreshNonParticipants = !this.refreshNonParticipants;
        this.clearSelected = !this.clearSelected;
        this.$notify({
            type: "success",
            title: "Выбранные члены РФСЛ успешно удалены из списка участников",
        });
    }
    async addPhotos() {
        document.getElementById("photosUpload").click();
    }
    async removePhoto() {
        const activePhoto = document
            .querySelector(".v-window-item--active .v-image__image")
            .getAttribute("style")
            .match(/url\(\"(.*)\"\)/)[1];
        console.log(activePhoto);
        const src = activePhoto.split("/")[activePhoto.split("/").length - 1];
        const photo = this.group.photos.find((item) => item.src === src);
        console.log(this.group.photos);
        await this.$store.dispatch(GroupModule.types.actions.DELETE_PHOTO, { groupId: this.group.id, photoId: photo.id });
        console.log(this.group.photos);
        this.photos.items = this.group.photos.map((item) => `${IMAGE_SERVER_URL}/public/${item.src}`);
        this.$notify({
            type: "success",
            title: "Выбранное фото успешно удалено",
        });
    }
    async addDocuments() {
        document.getElementById("documentsUpload").click();
    }
    async saveDocuments() {
        const form = [];
        for (let doc of this.uploadedDocuments) {
            form.push({
                ref: doc.name,
                rules: [Validation.required],
            });
        }
        form.push(this.documentsUpload);
        let hasError = Validation.validateWithView(form, this.$refs);
        if (hasError)
            return;
        const fd = new FormData();
        for (let doc of this.uploadedDocuments) {
            fd.append("docs", doc);
            fd.append("name", doc.userName);
        }
        fd.append("groupId", String(this.group.id));
        await this.$store.dispatch(GroupModule.types.actions.UPLOAD_DOCUMENTS, { fd });
        this.documentsTable.items.splice(0);
        this.group.documents.map((doc) => {
            this.documentsTable.items.push({
                ...doc,
                link: `${IMAGE_SERVER_URL}/documents/${doc.src}`,
            });
        });
        this.$notify({
            type: "success",
            title: "документы успешно загруженны",
        });
        this.clearDocuments();
    }
    async removeDocument() {
        for (let doc of this.documentsTable.selected) {
            await this.$store.dispatch(GroupModule.types.actions.DELETE_DOCUMENT, {
                groupId: this.group.id,
                documentId: doc.id,
            });
        }
        this.documentsTable.items.splice(0);
        this.group.documents.map((doc) => {
            this.documentsTable.items.push({
                ...doc,
                link: `${IMAGE_SERVER_URL}/documents/${doc.src}`,
            });
        });
        this.$notify({
            type: "success",
            title: "Выбранные документы успешно удалены",
        });
    }
    clearDocuments() {
        this.uploadedDocuments.splice(0);
    }
    async onGroupChanged() {
        const response = await this.$store.dispatch(GroupModule.types.actions.LOAD_GROUP, {
            id: parseInt(this.$route.params.id, 10),
        });
        this.group = response;
        this.refreshNonParticipants = !this.refreshNonParticipants;
    }
    async mounted() {
        const groupId = parseInt(this.$route.params.id, 10);
        if (Object.keys(this.groupsTable).length === 0) {
            await this.$store.dispatch(GroupModule.types.actions.GET_GROUPS);
        }
        this.group = this.groupsTable[groupId];
        this.group.documents.map((doc) => {
            this.documentsTable.items.push({
                ...doc,
                link: `${IMAGE_SERVER_URL}/documents/${doc.src}`,
            });
        });
        this.photos.items = this.group.photos.map((item) => `${IMAGE_SERVER_URL}/public/${item.src}`);
        this.documentsTable.loading = false;
    }
};
GroupPage = __decorate([
    Component({
        components: {
            UsersTable,
            GroupParticipants,
            GroupDialog,
            ApproveDialog,
            AdditionalAddresses,
        },
    })
], GroupPage);
export default GroupPage;
