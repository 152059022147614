import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
let AdditionalAddresses = class AdditionalAddresses extends Vue {
    get showDialog() {
        return this.value;
    }
    set showDialog(value) {
        this.$emit("input", value);
    }
    get addresses() {
        return this.additionalAddresses.map((addr) => {
            return [
                {
                    title: "Федеральный округ",
                    value: addr.address === null ? "Не указан" : addr.address.region.district.name,
                    isTree: false,
                },
                {
                    title: "Регион",
                    value: addr.address === null ? "Не указан" : addr.address.region.name,
                    isTree: false,
                },
                {
                    title: "Город",
                    value: addr.address !== null && addr.address.city !== null ? addr.address.city.name : "Не указан",
                    isTree: false,
                },
                {
                    title: "Адрес",
                    value: !addr.addressText ? "Не указан" : addr.addressText,
                },
            ];
        });
    }
};
__decorate([
    Prop({ type: Boolean, default: false })
], AdditionalAddresses.prototype, "value", void 0);
__decorate([
    Prop({ type: Array })
], AdditionalAddresses.prototype, "additionalAddresses", void 0);
AdditionalAddresses = __decorate([
    Component({
        components: {},
    })
], AdditionalAddresses);
export default AdditionalAddresses;
