import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import Validation from "@/helpers/Validation";
import GroupModule from "@/store/module/shared/GroupModule";
import UsersModule from "@/store/module/admin/UsersModule";
import AdminModule from "@/store/module/admin/AdminModule";
import HandbookModule from "@/store/module/shared/HandbookModule";
import UsersTable from "../../users/UsersTable.vue";
import ApproveDialog from "../../../shared/Approve.vue";
let GroupParticipants = class GroupParticipants extends Vue {
    constructor() {
        super(...arguments);
        this.approve = {
            add: false,
        };
        this.clearSelected = false;
        this.selectedUsers = [];
        this.nonParticipants = {
            nonParticipants: [],
        };
        this.amplua = {
            ref: "amplua",
            type: "select",
            label: "Амплуа",
            value: "",
            items: () => this.ampluaHandbook,
            showOnlyForSuperAuth: false,
            rules: [Validation.required],
        };
        this.selectboxes = [this.amplua];
    }
    onRefresh() {
        this.nonParticipants = this.getNonParticipants();
    }
    get showDialog() {
        return this.value;
    }
    set showDialog(value) {
        this.$emit("input", value);
    }
    setSelectedUsers(users) {
        this.selectedUsers = users;
    }
    get isSuperAdmin() {
        return this.$store.getters[AdminModule.types.getters.IS_SUPER_ADMIN];
    }
    get ampluaHandbook() {
        return this.$store.getters[HandbookModule.types.getters.AMPLUA]
            .filter((item) => item.id === 1 || item.id === 2)
            .sort((a, b) => a.id - b.id);
    }
    async addParticipants() {
        if (this.selectedUsers.length === 0) {
            return this.$notify({
                text: "Не выбран ни один участник",
            });
        }
        const hasError = Validation.validateWithView(this.selectboxes, this.$refs);
        if (hasError) {
            return this.$notify({
                type: "error",
                text: "Выберите амплуа участников",
            });
        }
        const participants = this.selectedUsers.map((user) => ({
            groupId: parseInt(String(this.group.id)),
            participantId: user.id,
            ampluaId: parseInt(this.amplua.value),
        }));
        await this.$store.dispatch(GroupModule.types.actions.ADD_PARTICIPANTS, { groupId: this.group.id, participants });
        this.clearSelected = !this.clearSelected;
        this.nonParticipants = this.getNonParticipants();
        this.$emit("groupChanged");
        this.$notify({
            type: "success",
            title: "Участники успешно добавлены",
        });
    }
    getNonParticipants() {
        let users = this.$store.getters[UsersModule.types.getters.USERS_BY_CATEGORIES].any
            .filter((u) => !u.isPremoderated && !u.isStopped)
            .filter((user) => {
            const isNonParticipant = this.group.participants.find((participant) => participant.participantId === user.id) === undefined;
            const fromRegion = (user.address.region !== null && user.address.region.id === this.group?.contacts.address.region.id) ||
                this.isSuperAdmin;
            return isNonParticipant && fromRegion;
        });
        return {
            nonParticipants: users,
        };
    }
    async mounted() {
        if (this.$store.getters[UsersModule.types.getters.USERS_BY_CATEGORIES].any.length === 0) {
            await this.$store.dispatch(UsersModule.types.actions.GET_USERS);
        }
        this.nonParticipants = this.getNonParticipants();
    }
};
__decorate([
    Prop({ default: false, type: Boolean })
], GroupParticipants.prototype, "value", void 0);
__decorate([
    Prop({ type: Object })
], GroupParticipants.prototype, "group", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], GroupParticipants.prototype, "refreshNonParticipants", void 0);
__decorate([
    Watch("refreshNonParticipants")
], GroupParticipants.prototype, "onRefresh", null);
GroupParticipants = __decorate([
    Component({
        components: {
            ApproveDialog,
            UsersTable,
        },
    })
], GroupParticipants);
export default GroupParticipants;
